// src/templates/pages/AllResourcesTag.js

import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import ResourcesHeader from '../../components/ResourcesHeader.js'
import ResourcesFilter from '../../components/ResourcesFilter.js'
import TagsFilter from '../../components/TagsFilter.js'
import ResourcesList from '../../components/ResourcesList.js'
import Pagination from '../../components/Pagination.js'

function AllResourcesTag(props) {
  const pageData = props.data.wpPage.template.resourcesPage

  return (
    <Layout>
      <Seo post={props.data.wpPage} />

      <ResourcesHeader
        title={props.data.wpPage.title}
        description={pageData.pageDescription}
      />

      <div className="mx-auto max-w-[97rem] px-responsive-xl-32 lg:px-responsive-xl-64">
        <div className="grid gap-responsive-xl-128 md:grid-cols-sidebar md:gap-responsive-xl-64">
          <div className="sidebar flex flex-wrap items-center md:ml-0 md:block md:border-r md:border-granite-200 md:pl-responsive-xl-24 md:pr-responsive-xl-32 md:text-left">
            <span className="mr-responsive-xl-4 mb-responsive-xl-16 font-bold text-meta-md uppercase leading-relaxed tracking-widest text-granite-600 md:hidden">
              Filter by:
            </span>

            <ResourcesFilter
              section={props.pageContext.section}
              resourceFilters={props.pageContext.resourceFilters}
            />

            <hr className="my-responsive-xl-32 hidden border-granite-200 md:block" />

            <TagsFilter
              postType={props.pageContext.postType}
              tagsList={props.pageContext.tagsList}
              currentTag={props.pageContext.tag}
            />
          </div>
          <div className="results space-y-responsive-xl-32">
            <ResourcesList resources={props.pageContext.resourcesList} />

            <Pagination
              currentPage={props.pageContext.currentPage}
              numPages={props.pageContext.numPages}
              pageSlug={props.pageContext.pageSlug}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AllResourcesTag

export const query = graphql`
  query {
    wpPage(template: { templateName: { eq: "Resources Listing" } }) {
      seo {
        ...SeoFragment
      }
      title
      template {
        ... on WpTemplate_ResourcesListing {
          resourcesPage {
            pageDescription
          }
        }
      }
    }
  }
`
